@import "@styles/common/_variables.scss"; @import "@styles/common/mixins.scss";
@import url('https://fonts.googleapis.com/css2?family=Noto+Color+Emoji&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.flex-center {
  @include flexCenter(true);

  &-col {
    @include flexCenter(false);
  }
}

.ff {
  height: 100%;
  width: 100%;
}

.ratio16x9 {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  aspect-ratio: 16/9;
}

.MuiPaper-root {
  overflow: hidden;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.emoji {
  font-family: "Noto Color Emoji", sans-serif;
  font-weight: 400;
  font-style: normal;
}