@mixin full {
  width: 100%;
  height: 100%;
}

@mixin flexCenter($isRow) {
  display: flex;
  justify-content: center;
  align-items: center;
  @if($isRow) {
    flex-direction: row;
  } @else {
    flex-direction: column;
  }
}